<template>
  <div>
    <c-tab
      ref="ctomissTab"
      type="horizon"
      :inlineLabel="true"
      :dense="true"
      :height="tabHeight"
      :tabItems="tabItems"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :tabParam.sync="tabParam"
          :attachInfo.sync="attachInfo"
          :contentHeight="contentHeight"
          @changeStatus="changeStatus"
          @getDetail="getDetail"
          @closePopup="closePopup"
        />
      </template>
    </c-tab>
  </div>
      <!-- class="full-height" -->
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'cto-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        councilId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      height: '',
      editable: true,
      splitter: 5,
      tab: 'councilInfo',
      tabParam: {
        councilId: '',
        plantCd: '',
        councilDate: '',
        councilContents: '',
        councilTypeCd: null,
        councilTitle: '',
        regUserId: '',
        chgUserId: '',
        
        attendeeInModels: [],
        attendeeOutModels: [],
        coucilVendorModels: [],
        deleteAttendeeInModels: [],
        deleteAttendeeOutModels: [],
        improveList: [],

        imprImmData: '',
        imprData: '',
      },
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'SOP_COUNCIL',
        taskKey: '',
      },
    };
  },
  computed: {
    tabItems() {
      return [
        { name: 'councilInfo', icon: 'edit', label: '기본정보 및 참석자', component: () => import(`${'./vendorCouncilInfo.vue'}`) },
        { name: 'councilImpr', icon: 'auto_fix_normal', label: '개선/진행상태', component: () => import(`${'./vendorCouncilImpr.vue'}`), disabled: (this.popupParam.councilId ? false : true)  },
      ]
    },
    tabHeight() {
      return String(this.contentHeight - 36);
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.detailUrl = selectConfig.mdm.cim.vendor.council.get.url;
      // url setting
      // code setting
      this.getDetail();
      // list setting
    },
    getDetail() {
      if (this.popupParam.councilId) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.councilId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.tabParam.imprImmData =[]
          this.tabParam.imprData =[]
          this.tabParam = _result.data;
          // 상세팝업 최초작성/최근수정 정보 노출
          this.$emit('setRegInfo', _result.data)
          this.$set(this.attachInfo, 'taskKey', this.popupParam.councilId)
          this.attachable = true;
          this.updateMode = true;

          if (this.tabParam.improveList && this.tabParam.improveList.length > 0) {
            // 즉시조치
            this.tabParam.imprImmData = this.$_.filter(this.tabParam.improveList, { ibmClassCd: 'IC00000005' });
            // 개선요청
            this.tabParam.imprData = this.$_.filter(this.tabParam.improveList, { ibmClassCd: 'IC00000001' });
          }
        },);
      } else {
        this.tabParam.councilDate = this.$comm.getToday();
      }
    },
    closePopup(data) {
      this.$emit('closePopup', data);  
    },
    changeStatus(data) {
      this.popupParam.councilId = data;

      this.getDetail();
    },
  }
};
</script>
